import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState } from "react";
import Slider from "react-slick";
import {
  Container,
  Stack,
  Flex,
  Button,
  Image,
  Grid,
  GridItem,
  Heading,
  Text,
  Divider,
  Box,
  useMediaQuery,
} from "@chakra-ui/react";
import {
  arrowBack,
  arrowSliderLeft,
  arrowSliderRight,
  iconFacebook,
  iconGlobe,
  iconTwitter,
  logoCertificacaoGPTW,
  logoCertificacaoGPTW2,
  wordCloudExample,
  logoCertificacao2024,
  logoCertificacao2025
} from "../../assets/images";
import CTAWithBackgroundImage from "../../components/CTA";
import { SocialButton } from "../../components/Footer";
import {
  Bar,
  HorizontalChart,
  HorizontalChartMobile,
  ChartExperience,
} from "../../components/Charts";
import { useLoaderData } from "react-router-dom";
import { Company } from "../../@types";
import { BsInstagram } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";
import { format, fromUnixTime, getYear } from "date-fns";
import brLocale from "date-fns/locale/pt-BR";
import ImageSelo from "../../components/Selo/ImageSelo";
// import { format, fromUnixTime, getYear } from "date-fns";
// import brLocale from "date-fns/locale/pt-BR";
import {
  AC,
  AL,
  AM,
  AP,
  BA,
  CE,
  DF,
  ES,
  GO,
  MA,
  MG,
  MS,
  MT,
  PA,
  PB,
  PE,
  PI,
  PR,
  RJ,
  RN,
  RO,
  RR,
  RS,
  SC,
  SE,
  SP,
  TO,
} from "../../assets/images/states";

const settings = {
  dots: false,
  arrows: false,
  fade: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 800,
  swipeToSlide: true,
};

const chartValues = [
  {
    name: "<2 anos - 42%",
    data: [42],
  },
  {
    name: "2-5 anos - 25%",
    data: [25],
  },
  {
    name: "6-10 anos - 16%",
    data: [16],
  },
];

export function Detalhe() {
  const company = useLoaderData() as Company;
  const [sliderCarrousel, setSlider] = useState<Slider | null>();
  const certificationDateToConvert = +company.DataCertificacao.substring(
    0,
    company.DataCertificacao.length - 3
  );

  const date = new Date(+company.DataCertificacao);
  const certificationYear = date.getFullYear();
  const month = date.toLocaleString("pt-br", { month: "long" });
  const certificationDate = `Atualizado em ${month} de ${date.getFullYear()}`;

  const images = company.photos ? company.photos.split(",") : null;
  const sectorWithoutNumbers = company.sector
    ?.replace(/\d+/g, "")
    .replaceAll(".", "");

  const companySize =
    company?.TotalEmployees < 100
      ? "Pequena"
      : company.TotalEmployees > 1000
      ? "Grande"
      : "Média";

  const flag =
    company.UF === "AC"
      ? AC
      : company.UF === "AL"
      ? AL
      : company.UF === "AM"
      ? AM
      : company.UF === "AP"
      ? AP
      : company.UF === "BA"
      ? BA
      : company.UF === "CE"
      ? CE
      : company.UF === "DF"
      ? DF
      : company.UF === "ES"
      ? ES
      : company.UF === "GO"
      ? GO
      : company.UF === "MA"
      ? MA
      : company.UF === "MG"
      ? MG
      : company.UF === "MS"
      ? MS
      : company.UF === "MT"
      ? MT
      : company.UF === "PA"
      ? PA
      : company.UF === "PB"
      ? PB
      : company.UF === "PE"
      ? PE
      : company.UF === "PI"
      ? PI
      : company.UF === "PR"
      ? PR
      : company.UF === "RJ"
      ? RJ
      : company.UF === "RN"
      ? RN
      : company.UF === "RO"
      ? RO
      : company.UF === "RR"
      ? RR
      : company.UF === "RS"
      ? RS
      : company.UF === "SC"
      ? SC
      : company.UF === "SE"
      ? SE
      : company.UF === "SP"
      ? SP
      : TO;

  const stamp = certificationYear === 2022
  ? logoCertificacaoGPTW2
  : certificationYear === 2023
  ? logoCertificacaoGPTW
  : certificationYear === 2024
  ? logoCertificacao2024
  : logoCertificacao2025;

  const [mobile] = useMediaQuery("(max-width: 990px)");

  return (
    <>
      <Container maxW={["100%", "1170px"]} overflow="hidden">
        <Stack
          p={["60px 0px", "30px 0 120px"]}
          align="start"
          spacing={["40px", "36px"]}
        >
          <Button
            as="a"
            href="/"
            variant="link"
            gap="16px"
            textTransform="uppercase"
            fontSize="12px"
            letterSpacing="-0.0047em"
            color="#11131C"
            pb={["20px", "unset"]}
          >
            <Image
              src={arrowBack}
              alt="Arrow back"
              h="8px"
              display={["none", "initial"]}
            />
            voltar para empresas
          </Button>
          <Grid
            templateColumns={["repeat(1, 1fr)", "repeat(5, 1fr)"]}
            height={["auto", "180px"]}
            gap={["40px", "12px"]}
            w="100%"
            pt={["0", "20px"]}
          >
            <GridItem colSpan={3} pl={["0px", "30px"]}>
              <Flex
                height="310px"
                gap={["20px", "22px"]}
                color="black"
                justifyContent="flex-start"
                alignItems={"center"}
              >
                <Box w={["100%", "165px"]} height={["233px", "233px"]} overflow="hidden">
                  <ImageSelo company={company}/>
                </Box>
                <Box>
                  <Stack spacing={0}>
                    <Heading
                      width="270px"
                      letterSpacing={["-1.14px", "-0.0112em"]}
                      fontSize={[
                        company.fantasyName.length > 30 ? "26px" : "30px",
                        company.fantasyName.length > 30 ? "36px" : "34px",
                      ]}
                      lineHeight={[
                        company.fantasyName.length > 30 ? "40px" : "44px",
                        company.fantasyName.length > 30 ? "54px" : "42px",
                      ]}
                      textOverflow="ellipsis"
                      fontWeight={700}
                    >
                      {company?.fantasyName}
                    </Heading>
                    <Text
                      fontSize="14px"
                      lineHeight="28px"
                      letterSpacing="-0.0047em"
                    >
                      {certificationDate}.
                    </Text>
                  </Stack>
                </Box>
              </Flex>
            </GridItem>
            <GridItem
              colSpan={[2, 2]}
              display="flex"
              justifyContent={["start", "flex-end"]}
              alignItems="center"
              gap="10px"
              h={["120px", "auto"]}
              pr={["10px", "unset"]}
              pos="relative"
            >
              <Box
                pos="absolute"
                left={["-100px", "unset"]}
                right={["unset", "210px"]}
              >
                <Bar score={company.score} />
              </Box>
              <Text
                ml={["125px", "20px"]}
                w={["unset", "200px"]}
                as="h5"
                fontSize={["18px", "21px"]}
                fontWeight={["700", "600"]}
                lineHeight="29px"
                letterSpacing={["-0.47px", "-0.0047em"]}
              >
                dos funcionários dizem que este é um ótimo lugar para trabalhar
              </Text>
            </GridItem>
          </Grid>
        </Stack>

        <Divider
          orientation="horizontal"
          borderWidth="2px"
          borderTop="1px solid #eee"
        />

        <Stack
          p={["30px 0 80px", "60px 0 120px"]}
          spacing={["40px", "80px"]}
          color="#11131C"
        >
          <Heading
            fontSize={["28px", "36px"]}
            lineHeight={["36px", "43px"]}
            letterSpacing={["-0.65px", "-0.007em"]}
            textAlign="center"
            fontWeight={["600", "medium"]}
          >
            Visão Geral da Empresa
          </Heading>
          <Grid templateColumns={["repeat(1, 1fr)", "repeat(3, 1fr)"]} gap={4}>
            <GridItem colSpan={2}>
              <Text
                fontSize="12px"
                lineHeight="20px"
                fontWeight={700}
                letterSpacing="0.0126em"
              >
                SOBRE A EMPRESA
              </Text>
              <Text
                fontSize="20px"
                lineHeight="32px"
                letterSpacing="-0.0047em"
                mt="14px"
              >
                {company?.description}
              </Text>

              <Grid
                templateColumns={["repeat(2, 1fr)", "repeat(3, 1fr)"]}
                gap={["40px", 4]}
                mt="28px"
              >
                <Stack spacing={["8px", "14px"]}>
                  <Heading
                    as="h3"
                    fontSize={["12px", "14px"]}
                    lineHeight="20px"
                    letterSpacing="0.0126em"
                  >
                    TAMANHO DA EMPRESA
                  </Heading>

                  <Text
                    fontSize={["16px", "20px"]}
                    lineHeight={["28px", "32px"]}
                    letterSpacing={["-0.2px", "unset"]}
                  >
                    {company?.TotalEmployees} ({companySize})
                  </Text>
                </Stack>

                <Stack spacing="14px" as={GridItem} gridColumnStart={[1, 2]}>
                  <Heading
                    as="h3"
                    fontSize={["12px", "14px"]}
                    lineHeight="20px"
                    letterSpacing="0.0126em"
                  >
                    SETOR
                  </Heading>

                  <Text
                    fontSize={["16px", "20px"]}
                    lineHeight={["28px", "32px"]}
                    letterSpacing={["-0.2px", "unset"]}
                  >
                    {sectorWithoutNumbers}
                  </Text>
                </Stack>

                <Stack spacing={["8px", "14px"]}>
                  <Heading
                    as="h3"
                    fontSize={["12px", "14px"]}
                    lineHeight="20px"
                    letterSpacing="0.0126em"
                  >
                    REDES E SITE
                  </Heading>

                  <Flex
                    height={["28px", "28px"]}
                    maxH={["28px", "28px"]}
                    align="center"
                    gap={["10px", "12px"]}
                  >
                    <SocialButton href={company?.website} label="Website">
                      <Image
                        src={iconGlobe}
                        alt="Website"
                        height={["28px", "27px"]}
                      />
                    </SocialButton>
                    {/*  <SocialButton href="#" label="Twitter">
                      <Image src={iconTwitter} alt="Twitter" />
                    </SocialButton> */}
                    <SocialButton href={company.facebook} label="Facebook">
                      <Image src={iconFacebook} alt="Facebook" />
                    </SocialButton>

                    {company.instagram && (
                      <SocialButton href={company.instagram} label="Instagram">
                        <BsInstagram fontSize={"26px"} />
                      </SocialButton>
                    )}

                    {company.linkedin && (
                      <SocialButton href={company.linkedin} label="Linkedin">
                        <FaLinkedinIn fontSize={"26px"} />
                      </SocialButton>
                    )}
                  </Flex>
                </Stack>
              </Grid>
            </GridItem>
            <GridItem colSpan={1} display={["none", "flex"]}>
              <Box ml="auto">
                <Text
                  fontSize="12px"
                  lineHeight="20px"
                  letterSpacing="0.0126em"
                  fontWeight={"700"}
                >
                  LOCALIZAÇÃO DA SEDE
                </Text>

                <Image src={flag} alt="Company location" mt="14px" />
                <Text
                  fontSize={"20px"}
                  lineHeight="32px"
                  letterSpacing="-0.0047em"
                  mt="10px"
                >
                  {company?.cidade}, {company?.UF}, Brasil
                </Text>
              </Box>
            </GridItem>
          </Grid>
        </Stack>

        <Divider
          orientation="horizontal"
          borderWidth="2px"
          borderTop="1px solid #eee"
        />

        {/* <Stack
          p={["30px 0 20px", "60px 0 120px"]}
          spacing="53px"
          color="#11131C"
        >
          <Heading
            fontSize={["28px", "36px"]}
            lineHeight={["36px", "43px"]}
            letterSpacing={["-0.65px", "-0.007em"]}
            textAlign="center"
            fontWeight={["600", "medium"]}
          >
            Dados demográficos dos funcionários
          </Heading>

          <Box height="201px" display={["none", "initial"]}>
            <HorizontalChart />
          </Box>

          <Box height="auto" display={["initial", "none"]}>
            <Text
              fontSize="20px"
              lineHeight="28px"
              fontWeight={600}
              letterSpacing="-0.47px"
              color="#11131C"
              textAlign="center"
              mb="15px"
            >
              Tempo
            </Text>
            <Flex
              gap="14px"
              justify="center"
              color="#11131C"
              width="90%"
              height="62px"
              flexWrap="wrap"
            >
              {chartValues.map((values, index) => (
                <Flex height="22px" align="center" gap="8px" key={index}>
                  <Box height="12px" width="12px" bgColor="#FF1628" />
                  <Text whiteSpace="nowrap" fontWeight={600}>
                    {values.name}
                  </Text>
                </Flex>
              ))}
            </Flex>
            <HorizontalChartMobile />
          </Box>
        </Stack> */}

        <Divider
          orientation="horizontal"
          borderWidth="2px"
          borderTop="1px solid #eee"
        />

        <Box p={["30px 0 80px", "60px 0 120px"]} color="#11131C">
          <Stack
            spacing={["20px", "33px"]}
            maxW={["unset", "3xl"]}
            mx="auto"
            alignItems="center"
            justify="center"
            textAlign="center"
          >
            <Heading
              as="h2"
              fontSize={["28px", "36px"]}
              lineHeight={["36px", "43px"]}
              letterSpacing={["-0.65px", "-0.007em"]}
              textAlign="center"
              fontWeight={["600", "medium"]}
            >
              Employee Experience
            </Heading>

            <Text
              fontSize="20px"
              lineHeight={["32px", "28px"]}
              letterSpacing="-0.0047em"
              color="black"
              fontWeight={["500", "normal"]}
            >
              O feedback dos colaboradores descrito abaixo foi coletado com a
              pesquisa Great Place To Work ® Trust Index
            </Text>

            <Button w="156px" h="51px" variant="primary">
              SAIBA MAIS
            </Button>
          </Stack>

          <Grid
            templateColumns={["repeat(1, 1fr)", "repeat(4, 1fr)"]}
            gap={[4]}
            mt="66px"
            color="#11131C"
          >
            <GridItem colSpan={1}>
              <Text
                w={["unset", "277px"]}
                fontSize="20px"
                lineHeight="32px"
                letterSpacing={["-0.47px", "-0.0047em"]}
                textAlign={["center", "left"]}
              >
                <b> {company.score}% </b>dos colaboradores da{" "}
                <b>{company.fantasyName} </b> dizem que a empresa é um ótimo
                lugar para trabalhar comparado com a nota média de 64% das
                empresas não certificadas de 2024
              </Text>
            </GridItem>
            <GridItem
              colSpan={3}
              height={["320px", "264px"]}
              maxH={["320px", "264px"]}
              pos="relative"
              p={0}
            >
              <Box
                pos="absolute"
                width={["90%", "100%"]}
                top={["0", "-40px"]}
                left={["-24px", "0"]}
              >
                <ChartExperience />
              </Box>
              <Stack
                height={["220px", "219px"]}
                w={["18%", "110px"]}
                pos="absolute"
                justifyContent="space-between"
                right={0}
                top={["40px", 0]}
                gap={["8px", "13px"]}
                py="20px"
              >
                <Box>
                  <Text
                    fontSize={["30px", "42px"]}
                    lineHeight="60px"
                    letterSpacing="-0.0112em"
                    color="#11131C"
                    fontWeight={600}
                    textAlign={["right", "unset"]}
                  >
                    {company.score}%
                  </Text>
                </Box>
                <Box>
                  {" "}
                  <Text
                    fontSize={["30px", "42px"]}
                    lineHeight="60px"
                    letterSpacing="-0.0112em"
                    color="#11131C"
                    fontWeight={600}
                    textAlign={["right", "unset"]}
                  >
                    64%
                  </Text>
                </Box>
              </Stack>
            </GridItem>
          </Grid>
        </Box>

        {/* <Divider
          orientation="horizontal"
          borderWidth="2px"
          borderTop="1px solid #eee"
        /> */}
        <Divider
          orientation="horizontal"
          borderWidth="2px"
          borderTop="1px solid #eee"
        />
        {images !== null && (
          <Box
            p={["30px 0 10px", "66px 0 10px"]}
            textAlign="center"
            color="#11131C"
          >
            <Heading
              fontSize={["28px", "36px"]}
              lineHeight={["36px", "43px"]}
              letterSpacing={["-0.65px", "-0.007em"]}
              textAlign="center"
              fontWeight={["600", "medium"]}
              mb={["40px", "32px"]}
            >
              A vida na {company.fantasyName}
            </Heading>
            <Box pos="relative">
              <Button
                height="28px"
                w="48px"
                pos="absolute"
                left="10px"
                display={["none", "flex"]}
                top="320px"
                bg="transparent"
                zIndex={90}
                onClick={() => sliderCarrousel?.slickPrev()}
                _hover={{
                  bgColor: "transparent",
                }}
                _active={{
                  bgColor: "transparent",
                }}
              >
                <Image src={arrowSliderLeft} alt="Arrow left" />
              </Button>

              <Button
                height="28px"
                w="48px"
                display={["none", "flex"]}
                pos="absolute"
                right="10px"
                top="320px"
                bg="transparent"
                zIndex={90}
                onClick={() => sliderCarrousel?.slickNext()}
                _hover={{
                  bgColor: "transparent",
                }}
                _active={{
                  bgColor: "transparent",
                }}
              >
                <Image src={arrowSliderRight} alt="Arrow right" />
              </Button>

              <Slider
                {...settings}
                ref={(sliderCarrousel) => setSlider(sliderCarrousel)}
              >
                {images.map((image, index) => (
                  <Flex
                    key={index}
                    w="100%"
                    flexDir="column"
                    alignItems="center"
                    justifyContent="center"
                    height={["360px", "706px"]}
                    px={["0", "60px"]}
                  >
                    <Image
                      src={image}
                      alt=""
                      mx="auto"
                      width={["100%"]}
                      objectFit="contain"
                      objectPosition={"top"}
                    />
                    <Text
                      fontSize="12px"
                      lineHeight="20px"
                      fontWeight={700}
                      mt="14px"
                    >
                      {company.fantasyName}, {company.cidade}
                    </Text>
                  </Flex>
                ))}
              </Slider>
            </Box>
          </Box>
        )}
        <CTAWithBackgroundImage />
      </Container>
    </>
  );
}
